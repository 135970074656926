










import { Component, Vue } from 'vue-property-decorator'

import SubscriptionTypesForm from '@/partials/forms/Events/SubscriptionTypesForm.vue'
import CenteredThin from '@/layouts/Panels/CenteredThin30-50.vue'

@Component({
  components: {
    CenteredThin,
    SubscriptionTypesForm
  }
})
export default class EventSubscriptionTypeCreate extends Vue {

}
